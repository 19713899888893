//// JS MAIN FILE ////

// Import Webflow
import { webflow } from "./modules/_webflow.js";
webflow();

// Import gsap library
import { gsapLib } from './ink-gsap/_ink-gsap.js';
gsapLib();
import { envTags } from './ink-gsap/_envTags.js';
envTags();

// Import modules
import Sliders from "./modules/_swiper.js";
Sliders();

// Import templates
