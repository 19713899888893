import Swiper from "swiper/bundle";

export default function Sliders() {
  var swiperNodes = "";
  var pagination = "<div class=swiper-pagination></div>";
  var next_prev_buttons =
    '<div class="swiper-button-prev"></div><div class="swiper-button-next"></div>';
  var scrollbar = '<div class="swiper-scrollbar"></div>';
  var swiperNodes = swiperNodes.concat(pagination, next_prev_buttons);
  /* loop throw all swipers on the page */
  $(".swiper").each(function (index) {
    $(this).append(swiperNodes);
  });
  var mySwiper = new Swiper(".first-swiper", {
    mousewheel: {
      invert: true,
      enabled: false,
    },
    // Optional parameters
    slidesPerView: 3,
    spaceBetween: 50,
    loop: true,
    loopedSlides: 3,
    speed: 300,
    // Enable lazy loading
    lazy: false,
    navigation: {
      nextEl: ".arrow-next",
      prevEl: ".arrow-previous",
    },
    keyboard: {
      enabled: true,
    },
    breakpoints: {
      0: {
        /* when window >=0px - webflow mobile portriat */ slidesPerView: 1,
        spaceBetween: 15,
      },
      478: {
        /* when window >= 478px - webflow mobile landscape */ slidesPerView: 2,
        spaceBetween: 15,
      },
      767: {
        /* when window >= 767px - webflow tablet */ slidesPerView: 2,
        spaceBetween: 30,
      },
      988: {
        /* when window >= 988px - webflow desktop */ slidesPerView: 3,
        spaceBetween: 50,
      },
      1920: {
        /* when window >= 988px - webflow desktop */ slidesPerView: 3,
        spaceBetween: 50,
      },
    },
    /* uncomment if you want autoplay slider
    autoplay: {
      delay: 3000,
    },
    */
    /* uncomment if you want scrollbar
     scrollbar: {
        el: '.swiper-scrollbar',
        hide: true,
      },
    */
  });
  var testimonialsSwiper = new Swiper(".swiper-2", {
    mousewheel: {
      invert: true,
      enabled: false,
    },
    // Optional parameters
    slidesPerView: 2,
    spaceBetween: 50,
    loop: true,
    loopedSlides: 2,
    speed: 300,
    // Enable lazy loading
    lazy: false,
    navigation: {
      nextEl: ".arrow-next-2",
      prevEl: ".arrow-previous-2",
    },
    keyboard: {
      enabled: true,
    },
    breakpoints: {
      0: {
        /* when window >=0px - webflow mobile portriat */ slidesPerView: 1,
        spaceBetween: 15,
      },
      478: {
        /* when window >= 478px - webflow mobile landscape */ slidesPerView: 1,
        spaceBetween: 15,
      },
      767: {
        /* when window >= 767px - webflow tablet */ slidesPerView: 2,
        spaceBetween: 30,
      },
      988: {
        /* when window >= 988px - webflow desktop */ slidesPerView: 2,
        spaceBetween: 50,
      },
      1920: {
        /* when window >= 988px - webflow desktop */ slidesPerView: 2,
        spaceBetween: 50,
      },
    },
    /* uncomment if you want autoplay slider
  autoplay: {
  delay: 3000,
  },
  */
    /* uncomment if you want scrollbar
  scrollbar: {
  el: '.swiper-scrollbar',
  hide: true,
  },
  */
  });
  var innovationSwiper = new Swiper(".swiper-3", {
    mousewheel: {
      invert: true,
      enabled: false,
    },
    // Optional parameters
    slidesPerView: 1,
    spaceBetween: 50,
    loop: true,
    loopedSlides: 1,
    speed: 300,
    // Enable lazy loading
    lazy: false,
    navigation: {
      nextEl: ".arrow-next-3",
      prevEl: ".arrow-previous-3",
    },
    autoplay: {
      delay: 4000,
    },
    keyboard: {
      enabled: true,
    },
    breakpoints: {
      0: {
        /* when window >=0px - webflow mobile portriat */ slidesPerView: 1,
        spaceBetween: 15,
      },
      478: {
        /* when window >= 478px - webflow mobile landscape */ slidesPerView: 1,
        spaceBetween: 15,
      },
      767: {
        /* when window >= 767px - webflow tablet */ slidesPerView: 1,
        spaceBetween: 30,
      },
      988: {
        /* when window >= 988px - webflow desktop */ slidesPerView: 1,
        spaceBetween: 50,
      },
      1920: {
        /* when window >= 988px - webflow desktop */ slidesPerView: 1,
        spaceBetween: 50,
      },
    },
    /* uncomment if you want autoplay slider
  autoplay: {
  delay: 3000,
  },
  */
    /* uncomment if you want scrollbar
  scrollbar: {
  el: '.swiper-scrollbar',
  hide: true,
  },
  */
  });
}
